import React, { Dispatch, Fragment, useEffect, useState } from 'react';
import CustomPopup from '../CustomPopup';
import InputField from '../../../library/inputField/inputField';
import ModuleAccessList from './ModuleAccessList';
import { IPPParagraph } from '../../../library/Heading/Heading';
import Button from '../../../library/Button/button';
import apiCallWrapper from '../../../apiwrapper/apiCallWrapper';
import Preset from '../../../utils/ModulePresets/Preset';
import { ModuleAccessLevel } from '../../../utils/Helper/enums';
import ConditionalLoader from '../../ConditionalLoader';

interface Props {
  show: boolean;
  onClose: (set: boolean) => void;
  setIsUpdated: Dispatch<React.SetStateAction<boolean>>;
}

// Create Preset Form
// show and onClose - to handle display of popup
// setIsUpdated - to inform the parent component about update (new preset created)
const CreatePresetForm = ({ show, onClose, setIsUpdated }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [presetName, setpresetName] = useState('');
  const [baseModules, setBaseModules] = useState<[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedAccessTypes, setSelectedAccessTypes] = useState<{ [moduleName: string]: string }>(
    {}
  );
  const [submitMessage, setSubmitMessage] = useState('Please Wait...');
  // Create Preset Module
  const submitCreateForm = async () => {
    setIsSubmitting(true);
    // Filtered module list (Without no access type)
    const moduleList = Object.keys(selectedAccessTypes)
      .filter((moduleName) => selectedAccessTypes[moduleName] !== ModuleAccessLevel.NOACCESS)
      .map((moduleName) => ({
        moduleName,
        accessType: selectedAccessTypes[moduleName]
      }));
    const presetObject = {
      presetName,
      moduleList
    };
    try {
      const presetInst = new Preset();
      const response = await presetInst.create(presetObject);
      setIsSubmitting(true);
      setSubmitMessage(response?.message ?? '');
      if (response?.status === 201) {
        setIsUpdated(true);
        setTimeout(() => {
          onClose(false);
        }, 1000);
      }
    } catch (error) {
      setSubmitMessage('Somethig went wrong');
    }
    setTimeout(() => {
      setSubmitMessage('Please Wait...');
      setIsSubmitting(false);
    }, 2000);
  };
  const closePopup = () => {
    setSelectedAccessTypes({});
    onClose(false);
  };

  useEffect(() => {
    // Fetching All modules
    const fetchModule = async () => {
      setIsLoading(true);
      try {
        const backEndServerUrl = String(process.env.REACT_APP_BACK_END_SERVER_URL ?? '');
        const subPath = '/organization/module';
        const url = `${backEndServerUrl}/organization/module`;
        const Axios = await apiCallWrapper(subPath, 'GET');
        const response = await Axios.get(url);
        const allModules = response.data;
        // Filter modules where isSystemModule is false
        const nonSystemModules = allModules.filter(
          (module: { isSystemModule: boolean }) => !module.isSystemModule
        );
        setBaseModules(nonSystemModules);
      } catch (error) {
        console.error('Error fetching system modules:', error);
      }
      setIsLoading(false);
    };
    void fetchModule();
  }, []);

  return (
    <CustomPopup show={show} onClose={closePopup} width={'min-w-[95%] md:min-w-fit px-4 md:px-9'}>
      <Fragment>
        <div>
          <div>
            <InputField
              id={'preset-name'}
              type="text"
              name={'preset'}
              className="border-2 rounded-md p-2"
              placeholder="Preset Name"
              value={presetName}
              onChange={(e) => {
                setpresetName(e.target.value);
              }}
            />
          </div>
          <br />
          <ConditionalLoader
            isLoading={isLoading}
            className="flex justify-center my-16"
            width={350}
          >
            <React.Fragment>
              <ModuleAccessList
                name={presetName}
                baseModules={baseModules}
                selectedAccessTypes={selectedAccessTypes}
                setSelectedAccessTypes={setSelectedAccessTypes}
                defaultAccessTypes={{}}
                systemModules={[]}
              />
              <div className="sticky bottom-[-16px] bg-white p-4 inset-x-0">
                <Button
                  className="inline-block mb-0 h-[42px] text-[#fff] text-[17px] w-[9rem] cursor-pointer bg-black rounded-md px-4 py-2"
                  buttonText="Submit"
                  onclick={submitCreateForm}
                  loading={isSubmitting}
                  disabled={
                    isSubmitting || !presetName || Object.keys(selectedAccessTypes).length === 0
                  }
                />
              </div>
            </React.Fragment>
          </ConditionalLoader>
        </div>
      </Fragment>
      <CustomPopup show={isSubmitting} onClose={() => {}} width={'w-41%'}>
        <IPPParagraph className="" headerText={submitMessage} />
      </CustomPopup>
    </CustomPopup>
  );
};

export default CreatePresetForm;
