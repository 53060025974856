import React from 'react';
import { useForm } from 'react-hook-form';
import queryString from 'query-string';
import { Link, NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import User from '../utils/User/User';
import { wrapAsyncFunction } from '../components/UtilityFunction/wrapAsyncFunction';
import InputField from '../library/inputField/inputField';
import Label from '../library/label/label';
import AuthLayout from '../layout/AuthLayout';

const ConfirmRegistration: React.FC<any> = (props: any) => {
  const navigate: NavigateFunction = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const { email } = queryString.parse(useLocation().search);
  const onSubmit = async (data: any) => {
    const userObj = new User({ email: String(email ?? ''), otp: data.otp });
    const confirmStatus = await userObj.doValidate();
    if (confirmStatus.statusCode === 201) {
      const path = '/login';
      navigate(path);
    } else {
      alert('Confirm Registration Fail');
    }
  };

  return (
    <AuthLayout>
      <div className="min-w-[290px] p-[0.5rem] rounded-lg shadow-lg pointer-events-auto justify-center bg-white">
        <form
          className="my-form"
          method="post"
          action=""
          name="frm_login"
          onSubmit={wrapAsyncFunction(handleSubmit(onSubmit))}
        >
          <div className="p-4">
            {/* loginsection start */}
            <div className="w-full">
              <div className="text-[22px] text-center mb-[15px]">OTP</div>
              <Label htmlFor="validationCustomEmail" className="inline mb-0 mt-2">
                {errors.otp == null ? 'Enter OTP' : <span>Please enter your OTP</span>}
              </Label>

              <InputField
                id={'otpConfirmRegistration'}
                className="h-7 w-full p-1.5   text-slate-400 rounded-l rounded-b border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
                type="text"
                {...register('otp', {
                  required: true
                })}
                style={
                  errors.otp == null
                    ? { border: '1px solid #666' }
                    : { border: '1px solid #e60014' }
                }
              />
            </div>
            {/* loginsection End */}
            <Label
              className="clear-left text-[#333] mt-1.5 border border-border-grey pb-2.5 hidden"
              LabelText={''}
            />
            <p className="mb-4 clear-both text-xs pb-0 mr-0 ml-0 mt-0"></p>
            <InputField
              id={'Submit'}
              type="submit"
              value="Submit"
              className="p-0 tracking-normal mb-4 h-10 text-[#fff] text-[17px] w-full shadow-none border border-login-bt-border bg-black rounded-[3px] cursor-pointer"
            />
            <div className="text-center mt-[15px] mb-[15px] mx-0">
              <Link className="mr-0 text-[16px] text-[#666]" to={`/otp?email=${props.inputEmail}`}>
                {' '}
                Resend verification code{' '}
              </Link>
              &nbsp;
            </div>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};
export default ConfirmRegistration;
