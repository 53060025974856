import React, { useState } from 'react';
import CopyIcon from '@rsuite/icons/Copy';

interface Props {
  name: string;
  accessType: string;
  command: string;
}

const ModuleinfoCard = ({ name, accessType, command }: Props) => {
  const [copiedCred, setCopiedCred] = useState<boolean>(false);

  const copyCredentials = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    await navigator.clipboard.writeText(`${command}`);
    setCopiedCred(true);
    setTimeout(() => {
      setCopiedCred(false);
    }, 1000);
  };
  return (
    <div className="flex flex-col max-h-full rounded-lg bg-white shadow-lg border border-gray dark:bg-neutral-700 p-4 pb-4">
      <h5 className="mb-1 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
        {name}
      </h5>
      <p className="text-base text-neutral-600 dark:text-neutral-200">
        <span className="font-semibold">Access permission:</span> {accessType}
      </p>
      <span className="font-semibold text-base text-neutral-600 dark:text-neutral-200">
        Clone command:
      </span>
      <div className="flex-grow flex flex-col justify-between px-3 py-2 border border-gray rounded-md mt-1 shadow-inner">
        <div className="w-full flex justify-end mb-1">
          <button
            className="text-black transition-all ease-in-out duration-100 hover:scale-105 shadow-sm border border-gray shadow-gray px-2 rounded-md -mr-2 -mt-1"
            onClick={copyCredentials}
            disabled={copiedCred}
          >
            {copiedCred ? (
              <small> Copied ✅</small>
            ) : (
              <small>
                <CopyIcon className="h-3 w-3" /> Copy
              </small>
            )}
          </button>
        </div>
        <p className="text-wrap break-words">{command}</p>
      </div>
    </div>
  );
};

export default ModuleinfoCard;
