import React from 'react';

export const Dropdown = ({ options, handleChange, selectedValue }: any) => (
  <div>
    <select
      className="border-black h-7 w-full p-[4.8px 8px] text-slate-400 border border-light-gray rounded-bg login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] rounded-lg"
      onChange={handleChange}
      value={selectedValue}
    >
      {options.map((option: any) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);
