import React from 'react';
import { IPPFourthLevelHeading, IPPParagraph } from '../library/Heading/Heading';
import useTimeOfDay from '../hooks/useTimeOfDay';
import InfoPopUp from './InfoPopUp';

interface Props {
  name: string;
  group?: string;
  isBtAdmin?: boolean;
  isAdmin?: boolean;
}

const UserGreeting = ({ name, group, isBtAdmin, isAdmin }: Props) => {
  const timeOfDay = useTimeOfDay();
  const userType = isBtAdmin
    ? 'Indigo Partner Portal | BT Admin Dashboard'
    : isAdmin
    ? 'Indigo Partner Portal | Admin Dashboard'
    : 'Indigo Partner Portal | User Dashboard';
  const userGreeting = isBtAdmin
    ? `${timeOfDay}, ${name}`
    : `${timeOfDay}, ${name} - (Organisation ${isAdmin ? 'Admin' : 'User'})`;
  return (
    <div className={`mx-0 p-0 mt-12 md:mt-8 pr-5 z-0 ${isBtAdmin ? 'mb-8' : 'mb-6'}`}>
      <IPPFourthLevelHeading
        headerText={userGreeting}
        className={'text-black text-[22px] mb-0.5 leading-[1.2] capitalize'}
      />
      {group && (
        <IPPFourthLevelHeading
          headerText={`Organisation - ${group}`}
          className={'text-black text-[22px] mb-0.5 leading-[1.2]'}
        />
      )}
      <IPPParagraph headerText={userType} className={'mb-0 text-[12px] mt-0 block'} />
      {!isBtAdmin && <InfoPopUp className="text-black font-bold text-lg mt-2" />}
    </div>
  );
};

export default UserGreeting;
