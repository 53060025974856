import React, { FormEvent } from 'react';
import InputField from '../library/inputField/inputField';
import Label from '../library/label/label';
/**
 * Search Component
 *
 * This Reusable React component filters the table based on user input.
 *
 * @param {object} text - The initial text for the search input placeholder.
 * @param {function} onChange - Event handler for input changes.
 *
 * @returns {JSX.Element} - Returns the JSX element for the reusable search component.
 */
interface SearchProps {
  text: string;
  onChange: (e: FormEvent<Element>) => void;
  hideLabel?: boolean;
}
// Reusable search component to filter the table based on user input.
const Search: React.FC<SearchProps> = ({ text, onChange, hideLabel }) => {
  // ToDo: Add functionality to handle submission, if needed.
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  return (
    <form className="w-full flex justify-end items-center gap-2" onSubmit={handleSubmit}>
      {!hideLabel && <Label className="visually-hidden text-[#7E7E7E]" LabelText="Search: " />}
      <InputField
        id={'header-search'}
        type="text"
        placeholder={text}
        className="flex-grow max-w-xs rounded-lg px-2 py-2 border border-light-gray min-w-[300px]"
        name="s"
        onChange={onChange}
      />
    </form>
  );
};

export default Search;
