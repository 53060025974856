import React, { MouseEventHandler, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@rsuite/icons/Menu';
import CloseIcon from '@rsuite/icons/Close';

interface ItemType {
  label: string;
  path: string;
  onClick?: MouseEventHandler;
}
interface Props {
  tabs?: ItemType[];
  links?: ItemType[];
}

const LeftNavPanel = ({ tabs, links }: Props) => {
  const [checked, setchecked] = useState(false);
  const { pathname } = useLocation();
  const [navHeight, setNavHeight] = useState<string>(
    window.innerHeight ? `${window.innerHeight - 160}px` : ''
  );

  useEffect(() => {
    if (window.innerWidth < 900) {
      setchecked(false);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    setNavHeight(window.innerHeight ? `${window.innerHeight - 160}px` : '');
  }, []);

  return (
    <>
      <input type="checkbox" className="hidden peer" checked={checked} readOnly />
      <div className="fixed h-full max-h-full w-0 left-0 flex flex-col flex-shrink-0 pt-0 bg-[#f8f8f8] box-border overflow-visible transition-all ease-in-out duration-700 shadow-lg border-r border-t border-gray md:shadow-none md:min-w-[60px] md:w-16 md:static peer-checked:w-80 z-10">
        <div className="flex justify-end align-middle pt-0 ml-0 sm:ml-2 md:ml-0 md:pt-4 absolute min-w-fit min-h-fit left-full md:left-0 md:sticky top-0 md:top-2 transition-all ease-in-out duration-700 text-nowrap">
          <button
            className="h-fit w-fit rounded-lg shadow-md bg-offwhite hover:bg-black hover:text-white p-1 px-2 mx-2 md:ml-0 mr-3 mt-2 md:mt-0"
            onClick={() => {
              setchecked(!checked);
            }}
          >
            {checked ? <CloseIcon className="h-4 w-4" /> : <MenuIcon className="h-4 w-4" />}
          </button>
        </div>
        <input type="checkbox" className="hidden peer" checked={checked} readOnly />
        <nav
          className={`min-h-[82%] w-full flex flex-col justify-between min-w-[250px] overflow-hidden overflow-y-auto transition-all ease-in-out duration-500 -translate-x-60 peer-checked:translate-x-0 px-4 ${
            checked ? 'delay-100' : 'delay-0'
          }
          `}
          style={{
            height: navHeight ?? '82%'
          }}
        >
          <ul className="pt-2 pb-0 w-full min-w-fit max-h-[65%] overflow-y-auto">
            {tabs?.length &&
              tabs.map((item, index) => (
                <li
                  className={`h-fit mb-1 ${item.path === pathname ? 'bg-gray rounded-lg' : ''}`}
                  key={index}
                >
                  <Link className="block w-full p-4" to={item.path}>
                    <span className="text-black"> {item.label} </span>
                  </Link>
                </li>
              ))}
          </ul>
          <ul className="bottom-0 left-0 pt-2 pb-0 w-full min-w-fit">
            {links?.length &&
              links.map((item, index) => (
                <li className="p-4" key={index}>
                  {item.onClick ? (
                    <p className="text-black cursor-pointer" onClick={item.onClick}>
                      {item.label}
                    </p>
                  ) : item.path ? (
                    <Link
                      className="text-black no-underline hover:text-iris m-0 p-0"
                      to={item.path}
                    >
                      <p className="text-black cursor-pointer p-0 m-0">{item.label}</p>
                    </Link>
                  ) : (
                    <p className="text-trans cursor-not-allowed p-0 m-0">{item.label}</p>
                  )}
                </li>
              ))}
          </ul>
        </nav>
      </div>
    </>
  );
};

export default LeftNavPanel;
