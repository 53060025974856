import React, { useId, useState } from 'react';
import ImageTag from '../../library/ImageTag/ImageTag';
import bin from '../../assets/img/bin.png';
import { handleApiResponse } from '../../utils/Helper/apiResponseHandler';
import CustomPopup from './CustomPopup';
import Button from '../../library/Button/button';
import DeleteOrganization from '../../utils/OrgUser/DeleteOrg';
import { IPPParagraph, IPPSubHeading } from '../../library/Heading/Heading';
/**
 * This common props takes in several parameters.
 * @organisationName - Obtain the organization name from the user row for which the data is about to be deleted.
 * @organisationEmail - Obtain the organization email from the user row for which the data is about to be deleted.
 **/
interface DeleteOrgProps {
  organisationName: string | any;
  organisationEmail: string | any;
  onDelete: () => void;
}
/* This component will delete Organization and it's corresponding users when user clicks on delete button.
props are used to send the organisation name and email to backend for deleting the data */

export const DeleteOrg: React.FC<DeleteOrgProps> = ({
  organisationName,
  organisationEmail,
  onDelete
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState(false);
  const [responseMessage, setResponseMessage] = useState<string>('Please Wait...');
  const [showAlert, setShowAlert] = useState(false);
  const [isShowing, setIsShowing] = useState(false);
  const uniqueId = useId();

  // sending the data From UI to backend via promise
  const deleteUser = async () => {
    setShowAlert(true);
    setShowLoader(true);
    setShow(false);
    const deleteOrganization = new DeleteOrganization({
      email: organisationEmail,
      partnerCompanyName: organisationName
    });

    const deleteStatus = await deleteOrganization.deleteOrgAdmin();
    const errorMessage = 'Failed to delete';
    const successMessage = 'Organisation deleted successfully';
    const response = handleApiResponse(deleteStatus.statusCode, errorMessage, successMessage);
    const responseBody = response.body;
    setResponseMessage(responseBody);
    if (deleteStatus.statusCode === 201) {
      onDelete();
    }
    setShowLoader(false);
  };

  // Wrap the popupCloseHandler function in useCallback to close the popup on clicking the close button
  const popupCloseHandler = () => {
    setShow(false);
  };

  // Wrap the onCancelHandler function in useCallback to cancel delete data on clicking the cancel button
  const onCancelHandler = () => {
    setShow(false);
  };

  // Method to show the popup on clicking the delete button
  const onDeleteHandler = () => {
    setShow(true);
  };

  // Wrap the popupCloseAlertHandler function in useCallback to close the popup on clicking the close button
  const popupCloseAlertHandler = () => {
    setShowAlert(false);
    setShow(false);
    setResponseMessage('Please Wait...');
  };

  return (
    <>
      <Button
        id={`delete-organisation-${uniqueId}`}
        aria-label="Delete Organisation"
        onclick={onDeleteHandler}
        onMouseEnter={() => setIsShowing(true)}
        onMouseLeave={() => setIsShowing(false)}
      >
        <ImageTag src={bin} width={'20px'} alt={''} name={'Delete'} />
        {isShowing && (
          <div className="group flex relative">
            <span
              className="px-1 py-1 group-hover:opacity-100 transition-opacity text-sm text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800 absolute -top-24 left-1/2
      -translate-x-1/2 translate-y-full m-4 mx-auto"
            >
              Delete Organisation
            </span>
          </div>
        )}
      </Button>
      <CustomPopup onClose={popupCloseHandler} show={show} width={'w-fit'}>
        <IPPSubHeading
          headerText={'Are you sure you want to Delete?'}
          className={'text-lg font-medium mb-4'}
        />
        <div className="flex justify-center">
          <Button
            className="h-10 px-4 py-[1.75] bg-white hover:bg-black hover:text-white text-[#2c323f] rounded focus:outline-none border border-spacing-1 border-black mr-3"
            buttonText="Cancel"
            onclick={onCancelHandler}
          />
          <Button
            className="px-4 py-[1.75] bg-white hover:bg-black hover:text-white text-[#2c323f] rounded focus:outline-none border border-spacing-1 border-black ml-3"
            buttonText="Delete"
            onclick={deleteUser}
          />
        </div>
      </CustomPopup>
      {showAlert && (
        <CustomPopup onClose={popupCloseAlertHandler} show={showAlert} width={'w-41%'}>
          <div className="max-h-30%">
            <IPPParagraph
              headerText={`${responseMessage}`}
              className={'mr-0 mb-0.5 ml-0 text-[17px]'}
            />
          </div>
        </CustomPopup>
      )}
    </>
  );
};
