export const routes = {
  root: '/',
  login: '/login',
  termsAndConditions: '/termsandconditions',
  mfa: '/mfa',
  otp: '/otp',
  resetPassword: '/reset/otp',
  setupPassword: '/setup/password',
  default: '/*'
};
export const btAdminRoutes = {
  dashboard: '/btadmin',
  partnerAdmin: '/btadmin/partners',
  modules: '/btadmin/modules',
  analytics: '/btadmin/analytics',
  presets: '/btadmin/presets',
  audit: '/btadmin/audit',
  register: '/register/*'
};
export const adminRoutes = {
  dashoard: '/admin'
};
export const userRoutes = {
  dashboard: '/user'
};
