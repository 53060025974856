import React, { createContext, useContext, useState } from 'react';
import Loader from '../library/Loader/loader';

interface Props {
  header: React.ReactNode;
  footer: React.ReactNode;
  children: React.ReactNode;
}

const PanelLayout = ({ header, footer, children }: Props) => {
  return (
    <div className="h-screen w-screen max-h-screen max-w-screen overflow-hidden">
      <Loader />
      <div className="">{header}</div>
      <div className="flex h-full w-full max-h-full max-w-full">{children}</div>
      <div className="">{footer}</div>
    </div>
  );
};

export default PanelLayout;
