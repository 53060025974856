import React, { useEffect, useState } from 'react';
import Button from '../../library/Button/button';
import PartnerDomainRow from './PartnerDomainRow';
import IDomain from '../../utils/Domain/Domain.interface';
import CustomPopup from '../BtAdminPanel/CustomPopup';
import { IPPHeading } from '../../library/Heading/Heading';
import { IAlertState } from '../../utils/Helper/type';

interface PartnerDomainListProps {
  domains: string[];
  emailList: string[];
  onDomainDelete: (domain: string[]) => void;
  isLoading: boolean;
}

const PartnerDomainList: React.FC<PartnerDomainListProps> = ({
  domains,
  emailList,
  onDomainDelete,
  isLoading
}) => {
  const [alertState, setAlertState] = useState<IAlertState>({
    show: false,
    message: null
  });
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const toDomainList = (domains: string[]): IDomain[] => {
    const domainList = domains.map((domain) => ({
      domainName: domain,
      isSelected: false
    }));
    return domainList;
  };
  const fromDomainList = (domains: IDomain[]): string[] => {
    const domainList = domains
      .filter((domain) => !domain.isSelected)
      .map((domain) => domain.domainName);
    return domainList;
  };

  const [domainCheckboxList, setDomainCheckboxList] = useState<IDomain[]>(toDomainList(domains));
  const domainListChanged = fromDomainList(domainCheckboxList).length === domains.length;

  const customAlert = (message: string) => {
    setAlertState({
      show: true,
      message
    });
  };

  const closeAlert = () => {
    setAlertState({
      show: false,
      message: null
    });
  };

  const handleDomainDelete = async () => {
    setIsDeleting(true);
    const selectedDomainsToBeDeleted = fromDomainList(domainCheckboxList);
    if (selectedDomainsToBeDeleted.length !== domains.length) {
      await onDomainDelete(selectedDomainsToBeDeleted);
    } else {
      customAlert('Sorry there is no domain selected for deletion.');
    }
    setIsDeleting(false);
  };

  useEffect(() => {
    const updateDomain = toDomainList(domains);
    setDomainCheckboxList(updateDomain);
  }, [domains.length]);

  return (
    <div className="block w-full">
      {domainCheckboxList.map((domain: IDomain, index: number) => (
        <PartnerDomainRow
          domain={domain.domainName}
          key={index}
          isSelected={domain.isSelected}
          onDomainChecked={(isSelected: boolean) => {
            if (!emailList.some((email) => email.includes(domain.domainName))) {
              const newDomainCheckboxList = [...domainCheckboxList];
              newDomainCheckboxList[index].isSelected = isSelected;
              setDomainCheckboxList(newDomainCheckboxList);
            } else {
              customAlert('Sorry there is existing user under this domain.');
            }
          }}
        />
      ))}
      {domainCheckboxList.length ? (
        <Button
          className="px-4 py-[1.75] h-7 w-24 bg-offwhite hover:bg-black disabled:bg-offwhite disabled:hover:bg-offwhite disabled:hover:cursor-not-allowed disabled:hover:text-[#2c323f] hover:text-white text-[#2c323f] rounded focus:outline-none border border-spacing-1 border-black ml-3"
          onclick={handleDomainDelete}
          disabled={isDeleting || domainListChanged}
          loading={isDeleting}
          loadingColour="black"
          buttonText="Delete"
        ></Button>
      ) : (
        <></>
      )}
      <CustomPopup show={alertState.show} onClose={closeAlert} width={'w-41%'}>
        <div>
          <IPPHeading
            className="text-lg pb-4 whitespace-pre-wrap"
            headerText={alertState.message ?? ''}
          />
        </div>
      </CustomPopup>
    </div>
  );
};

export default PartnerDomainList;
