import React from 'react';
import QRCode from 'react-qr-code';
import OtpValidate from './OtpValidate';
import { IPPHeading } from '../library/Heading/Heading';

// this a reusable component for setting up the autheticator in the google authenticator and validate it.
// It takes the secrect code and a onOtpVerification callback function.
interface MfaSetupQrCodeProps {
  secretCode: string;
  onOtpVerification: Function;
  isLoading: boolean;
}

const MfaSetupQrCode: React.FC<MfaSetupQrCodeProps> = ({
  secretCode,
  onOtpVerification,
  isLoading
}) => {
  return (
    <div className="flex flex-col justify-center items-start">
      <IPPHeading className="w-full text-2xl text-left mb-3" headerText="Setup authenticator" />
      <p className="mb-1 text-xs mt-1 block">Please scan this QR code with Google Authenticator.</p>
      <p className="mb-1 text-xs block">Once you complete the setup, please press done button.</p>
      <div className="h-auto my-0 mx-auto w-full max-w-[85px] mt-2">
        <QRCode
          size={256}
          className="h-auto max-w-full w-full"
          value={'otpauth://totp/IndigoPartnerPortal?secret=' + secretCode}
          viewBox={'0 0 256 256'}
        />
      </div>
      <div className="flex flex-1 flex-row justify-center w-full">
        <OtpValidate
          isLoading={isLoading}
          onOtpSubmission={(code: string) => {
            onOtpVerification(code);
          }}
        />
      </div>
    </div>
  );
};

export default MfaSetupQrCode;
