import React, { useCallback, useState } from 'react';
import ImageTag from '../../library/ImageTag/ImageTag';
import bin from '../../assets/img/bin.png';
import { useNavigate } from 'react-router-dom';
import { handleApiResponse } from '../../utils/Helper/apiResponseHandler';
import CustomPopup from '../BtAdminPanel/CustomPopup';
import Button from '../../library/Button/button';
import DeleteUsers from '../../utils/OrgUser/DeleteUser';
import { IPPParagraph, IPPSubHeading } from '../../library/Heading/Heading';

interface DeleteUserProps {
  orgEmail: string;
  email: string;
  disabled: boolean;
  onDeleteUser: (email: string) => void;
}
/* This component will delete Organization users when user clicks on delete button. Props are used to send the organisation email and user email to backend for deleting the data */

export const DeleteUser: React.FC<DeleteUserProps> = ({
  orgEmail,
  email,
  disabled,
  onDeleteUser
}) => {
  // useState to show the popup loader and response message
  const [show, setShow] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState(false);
  const [responseMessage, setResponseMessage] = useState<string>('Please Wait...');
  const [showAlert, setShowAlert] = useState(false);
  const [isShowing, setIsShowing] = useState(false);

  // sending the data From UI to backend via promise
  const deleteUser = async () => {
    setShowLoader(true);
    setShow(!show);
    setShowAlert(true);
    setTimeout(() => setShowLoader(false), 1000);

    const deleteOrganization = new DeleteUsers({
      email,
      orgEmail
    });

    const deleteStatus = await deleteOrganization.deleteUser();
    setShowLoader(showLoader);
    const errorMessage = 'Failed to delete';
    const successMessage = 'User deleted successfully';
    const response = handleApiResponse(deleteStatus.statusCode, errorMessage, successMessage);
    const responseBody = response.body;
    setResponseMessage(responseBody);

    if (deleteStatus.statusCode === 201) {
      onDeleteUser(email);
    }
  };

  // Wrap the popupCloseHandler function in useCallback to close the popup on clicking the close button
  const popupCloseHandler = () => {
    setShow(false);
    setShowAlert(false);
  };

  // Wrap the onCancelHandler function in useCallback to cancel delete data on clicking the cancel button
  const onCancelHandler = () => {
    setShow(false);
  };

  // Method to show the popup on clicking the delete button
  const onDeleteHandler = async () => {
    setShow(!show);
  };

  // Wrap the popupCloseAlertHandler function in useCallback to close the popup on clicking the close button
  const popupCloseAlertHandler = useCallback(() => {
    setShowAlert(false);
    setShow(false);
  }, []);

  return (
    <>
      <Button
        onclick={onDeleteHandler}
        onMouseEnter={() => setIsShowing(true)}
        onMouseLeave={() => setIsShowing(false)}
        disabled={disabled}
        className={disabled ? 'cursor-not-allowed' : ''}
      >
        <ImageTag key="Delete" src={bin} width={'20px'} alt={''} name={''} />
        {isShowing && (
          <div className="group flex relative">
            <span
              className="px-1 py-1 group-hover:opacity-100 transition-opacity text-sm text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800 absolute -top-24 left-1/2
        -translate-x-1/2 translate-y-full m-4 mx-auto"
            >
              Delete User
            </span>
          </div>
        )}
      </Button>
      <CustomPopup onClose={popupCloseHandler} show={show} width={'w-41%'}>
        <IPPSubHeading
          headerText={'Are you sure you want to Delete?'}
          className={'text-lg font-medium mb-4'}
        />
        <div className="flex justify-center">
          <Button
            className="h-10 px-4 py-[1.75] bg-white hover:bg-black hover:text-white text-[#2c323f] rounded focus:outline-none border border-spacing-1 border-black mr-3"
            buttonText="Cancel"
            onclick={onCancelHandler}
          />
          <Button
            className="px-4 py-[1.75] bg-white hover:bg-black hover:text-white text-[#2c323f] rounded focus:outline-none border border-spacing-1 border-black ml-3"
            buttonText="Delete"
            onclick={deleteUser}
          />
        </div>
      </CustomPopup>
      {showAlert && (
        <CustomPopup onClose={popupCloseAlertHandler} show={showAlert} width={''}>
          <div className="max-h-30%">
            <IPPParagraph
              headerText={`${responseMessage}`}
              className={'mr-0 mb-0.5 ml-0 text-[17px]'}
            />
          </div>
        </CustomPopup>
      )}
    </>
  );
};
