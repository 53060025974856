import React, { useState } from 'react';
import BtLogo from '../../library/Logo/BtLogo';
import Profile from './Profile';

interface Props {
  btAdmin?: boolean;
  isAdmin?: boolean;
  isUser?: boolean;
}
// Header component for Admin and BtAdmin pages
const AdminTopBar: React.FC<any> = ({ btAdmin, isAdmin, isUser }: Props) => {
  // states to change the background color in logo
  const [isLogout, setIsLogout] = useState<boolean>(false);
  return (
    <div className="flex justify-between items-center shadow-sm bg-[#f8f8f8] pr-4">
      <div className="md:w-80 bg-white py-4 md:py-6 px-4 md:px-12">
        <div className="w-fit h-fit mx-auto">
          <BtLogo />
        </div>
      </div>
      <div className="px-0 py-3 sm:pl-4 sm:py-2 bg-white rounded-md">
        {/* Here the Profile is a component which contains the Admin and BT Admin User info section
              i.e., Admin/BtAdmin name, profile along with a logout button. */}
        <Profile setIsLogout={setIsLogout} btAdmin={btAdmin} isAdmin={isAdmin} isUser={isUser} />
      </div>
    </div>
  );
};

export default AdminTopBar;
