import React, { Fragment, useState } from 'react';
import CustomPopup from './CustomPopup';
import IUserList from '../../utils/UserList/UserList.interface';
import { EditUserForm } from '../AdminPanel/EditUser';
import { DeleteUser } from '../AdminPanel/DeleteUser';
import ReactLoading from 'react-loading';
import ResetMfa from './ResetMfa';
import ConditionalLoader from '../ConditionalLoader';

/**
 * This common props takes in several parameters.
 * @usersData - These prop is likely used to store the users list received from back-end.
 * @isLoading - This prop is  used to implement the loader while data is being fetched from back-end.
 * @query - These prop is used to implement search functionality based on user's input
 * @show - These prop is used to show the userlist popup.
 * @setShow - These prop is used to update the @show based on user's input
 * @disabled - This prop is used to pass the disabled field in edituser and delete user form.
 **/

interface OrgUserProps {
  usersData: any[];
  query: string | null;
  disabled: boolean;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  onDeleteUser: (email: string) => void;
  onEditUser: (updatedUser: any) => void;
}
// component for showing Organization users for a specific Organization Admin.
export const OrgUserForm: React.FC<OrgUserProps> = ({
  usersData,
  query,
  disabled,
  show,
  setShow,
  isLoading,
  onDeleteUser,
  onEditUser
}) => {
  // Method to close the popup on clicking the close button
  const popupCloseHandler = (e: boolean) => {
    setShow(e);
  };

  return (
    <CustomPopup onClose={popupCloseHandler} show={show} width={'w-41%'}>
      <div className="w-fit h-fit">
        <Fragment>
          <div className="flex start justify-between px-4 py-4 border-b border-black">
            <h4 className="mb-0 leading-normal text-2xl text-black">Partner Users</h4>
          </div>
          <ConditionalLoader isLoading={isLoading} className="flex justify-center" width={200}>
            <div className="grow shrink basis-auto px-0 sm:p-4 block max-h-[30rem]">
              {usersData.length > 0 ? (
                <table className="w-100%  border-collapse ">
                  <thead className="table-header-group align-middle bg-[#f8f8f8] ">
                    <tr className="text-black text-left table-row align-inherit">
                      <th className="font-semibold text-sm uppercase px-2 sm:px-6 py-4 text-center">
                        Users Name
                      </th>
                      <th className="font-semibold text-sm uppercase px-2 sm:px-6 py-4 text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersData
                      .filter((user: any) => user.name.toLowerCase().includes(query))
                      .map((dataSet: IUserList, index: number) => {
                        return (
                          <tr key={index}>
                            <td className="px-2 sm:px-6 py-4 text-center">
                              <div className="w-48">
                                <p className="text-ellipsis overflow-hidden">{dataSet.email}</p>
                              </div>
                            </td>
                            <td className="px-2 sm:px-6 py-4 text-center">
                              {' '}
                              <EditUserForm
                                orgPartnerCompanyEmail={dataSet.orgEmail}
                                userName={dataSet.name}
                                orgJobTitle={dataSet.role}
                                orgPartnerCompanyName={dataSet.companyName}
                                userEmail={dataSet.email}
                                disabled={disabled}
                                userPhone={dataSet.phone}
                                onEditUser={onEditUser}
                              />
                              <DeleteUser
                                email={dataSet.email ?? ''}
                                orgEmail={dataSet.orgEmail ?? ''}
                                disabled={disabled}
                                onDeleteUser={onDeleteUser}
                              />
                              <ResetMfa userEmail={dataSet.email} disabled={disabled} />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              ) : (
                <div className="px-2 py-2 text-center pt-4 font-bold">
                  <h1 className="text-bold">No users found under this partner ☹️ !!</h1>
                </div>
              )}
            </div>
          </ConditionalLoader>
        </Fragment>
      </div>
    </CustomPopup>
  );
};
