import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import BtLogo from '../../library/Logo/BtLogo';
import ResponsiveMenu from '../ResponsiveMenu';
import { downloadFile } from '../../utils/Helper/downloadFiles';

// Header component for all other pages. This is the basic header which we are using for Home, Login, Register Pages respectively.
const WelcomeHeader: React.FC<any> = () => {
  // Use State to handle the header for mobile view. If it is true, then use will be able to see the header as a burger button.
  const [active, setActive] = useState<boolean>(false);

  const showMenu = (): void => {
    setActive(!active);
  };
  // Download UserGuideBook and script.
  const downloadUserGuide = () => {
    const fileUrl = '/SignInGuideBook.pdf';
    const fileName = 'SignInGuideBook'; // Set the desired file name
    downloadFile(fileUrl, fileName);
  };

  return (
    <div className="w-screen overflow-hidden bg-bt-white py-1 md:py-4 shadow-lg">
      <div className="w-full flex justify-between items-center px-4">
        <div className="col-start-7 col-end-8 bg-white">
          <div className="w-fit h-fit mx-auto">
            <Link className="lg:max-h-12 " to="/" title="Home" rel="home">
              <BtLogo />
            </Link>
          </div>
        </div>
        <div className="text-white inline-flex p-3 hover:bg-gray-900 rounded md:hidden lg:hidden ml-auto hover:text-white outline-none nav-toggler">
          {!active ? (
            <div onClick={showMenu}>
              <div className="w-6 h-[3px] bg-black m-[7px] duration-500"></div>
              <div className="w-6 h-[3px] bg-black m-[7px] duration-500"></div>
              <div className="w-6 h-[3px] bg-black m-[7px] duration-500"></div>
            </div>
          ) : (
            <div onClick={showMenu}>
              <div className="w-6 h-[3px] bg-black m-[7px] duration-500 translate-y-[9px] -rotate-45"></div>
              <div className=" opacity-0 w-6 h-[3px] bg-black m-[7px] duration-500"></div>
              <div className=" w-6 h-[3px] bg-black m-[7px] duration-500 translate-x-0 -translate-y-[11px] rotate-45"></div>
            </div>
          )}
        </div>
        <div className="hidden md:flex md:text-sm md:gap-6 items-center text-black font-bold">
          <Link className="flex items-center whitespace-nowrap" to={''} onClick={downloadUserGuide}>
            <span className="w-fit">Sign-in GuideBook</span>
            <svg
              className="w-4 h-4 text-gray-800 dark:text-black"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 18"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 1v12m0 0 4-4m-4 4L1 9"
              />
            </svg>
          </Link>
          <Link className="whitespace-nowrap" to="/login">
            Sign-in
          </Link>
        </div>
      </div>
      <ResponsiveMenu showMenu={showMenu} active={active} />
    </div>
  );
};

export default WelcomeHeader;
