import React, { Fragment } from 'react';
import Button from '../../library/Button/button';
import Label from '../../library/label/label';
import { ConditionalStyleComponent } from '../ConditionalStyle';
import InputField from '../../library/inputField/inputField';

const UserEmailInfo: React.FC<any> = (props: any) => {
  const isValid: boolean = props.showLoader || !props.isEmailValid;
  return (
    <Fragment>
      {props.isSaveUserPcd}
      {props.isSaveUserDetails}
      <div
        className={
          props.isSaveUserPcd && props.isSaveUserDetails
            ? 'block'
            : props.isSaveUserPcd
            ? 'block mb-[30px] mt-[72px]'
            : props.isSaveUserDetails
            ? 'block mt-[84px]'
            : 'block'
        }
      >
        <h2
          className={
            props.isSaveUserDetails && props.isSaveUserPcd
              ? 'py-5 px-[30px] my-0 -mx-[30px] bg-[#666] text-white cursor-pointer text-[1em] z-[1] min-h-[45px] border border-white'
              : 'py-5 px-[30px] my-0 -mx-[30px] bg-[#ddd] text-[#333] cursor-pointer text-[1em] z-[1] min-h-[45px] border border-white'
          }
        >
          <ConditionalStyleComponent
            condition={props.isSaveUserDetails && props.isSaveUserPcd}
            trueStyle={{ display: 'block', textColor: 'white' }}
            falseStyle={{
              display: 'block',
              textColor: '#333'
            }}
            isSaveUserDetails={false}
            isSaveUserPcd={false}
          >
            Account Details
          </ConditionalStyleComponent>
        </h2>
      </div>
      {/* start Create BT user name */}
      {props.isSaveUserPcd && props.isSaveUserDetails && (
        <div className="block">
          <h3 className="pr-[25%] mt-[14px] mb-2.5 text-[21px] leading-[1.5em] text-[#333]">
            Account Details
          </h3>
          <p className="mt-0 mr-0 mb-2.5 ml-0 text-[14px]">
            Please note that you'll need to access this email address to activate your account.
          </p>
          <div className="block">
            <div className="p-0 w-full float-left relative">
              <div className="w-full my-5 mx-0 relative table border-separate">
                <Label
                  className="text-right leading-[40px] pr-2.5 pl-0 w-fit sm:w-[31.25%] mb-[5px] float-left required-field whitespace-nowrap sm:whitespace-normal"
                  LabelText="Email:"
                ></Label>
                <div className="px-0 w-full sm:w-[31.25%] min-w-[200px] table">
                  <InputField
                    id="userEmail"
                    type="text"
                    className="h-10 block w-full py-[7px] px-[14px] rounded bg-white test-[#555]"
                    value={props.inputEmail}
                    onChange={props.handleOnChange}
                    reference={props.clickRef}
                    required={true}
                    style={
                      props.error.errorMessage == null
                        ? { border: '1px solid #666' }
                        : { border: '1px solid #e60014' }
                    }
                    onClick={() => {
                      props.setEmailEnable(true);
                    }}
                  />
                  {props.error.errorMessage && (
                    <div className="h-auto w-full float-left bg-[#E60014] text-white mt-2.5 relative table mb-[15px]">
                      <span className="px-0 bg-[url('https://my.bt.com/s/apps/appsconnectedcare/img/up_icon_red.png')] bg-no-repeat absolute -top-[10px] left-2.5 w-[24px] h-[11px] mt-0 mr-0 mb-2.5 ml-2.5"></span>
                      <div className="pt-2.5 pr-2.5 pb-2.5 pl-[15px]">
                        <span className="left-[15px] py-0 h-10 w-10 m-0 text-[.95em]">
                          {' '}
                          {props.error.errorMessage}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full my-5 mx-0 relative inline-flex border-separate"></div>
          <div className="block"></div>
          <div className="my-5 mx-0 w-full" style={{ borderBottom: '#ddd solid 1px' }}></div>
          <div className="float-left">
            <Button
              className="bg-[white]  text-black border-black py-[11px] px-5 my-[5px] mx-0 w-full text-center h-10 text-[.9em] rounded-md inline-block border leading-[1.168px]"
              onclick={() => {
                props.setSaveUserPcd(false);
              }}
              buttonText="Cancel"
            />
          </div>
          <div className="float-right">
            <ConditionalStyleComponent
              condition={props.isEmailValid}
              trueStyle={{
                backgroundColor: '#000',
                color: 'white',
                border: 'black',
                paddingTop: '11px',
                paddingBottom: '11px',
                paddingLeft: '1.25rem',
                paddingRight: '1.25rem',
                marginTop: '5px',
                marginBottom: '5px',
                marginLeft: '0',
                marginRight: '0',
                width: '100%',
                textAlign: 'center',
                height: '2.5rem',
                fontSize: '0.9rem',
                borderRadius: '0.375rem',
                display: 'inline-block'
              }}
              falseStyle={{
                backgroundColor: '#808080',
                cursor: 'not-allowed',
                color: 'white',
                border: '#808080',
                opacity: '0.5',
                paddingTop: '11px',
                paddingBottom: '11px',
                paddingLeft: '1.25rem',
                paddingRight: '1.25rem',
                marginTop: '5px',
                marginBottom: '5px',
                marginLeft: '0',
                marginRight: '0',
                width: '100%',
                textAlign: 'center',
                height: '2.5rem',
                fontSize: '0.9rem',
                borderRadius: '0.375rem',
                display: 'inline-block'
              }}
              isSaveUserDetails={false}
              isSaveUserPcd={false}
            >
              <Button
                className={''}
                disabled={isValid}
                loading={props.showLoader}
                onclick={props.createUser}
                buttonText="Continue"
              />
            </ConditionalStyleComponent>
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default UserEmailInfo;
