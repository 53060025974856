import { AxiosResponse } from 'axios';
import { APIResponse } from '../Helper/type';
import { handleApiResponse } from '../Helper/apiResponseHandler';
import Iverify from './Verify.interface';
import { post } from '../Request/Request';

// Declaring the web server path to call api
const backEndServerUrl = String(process.env.REACT_APP_BACK_END_SERVER_URL ?? '');
/* this class is used to call change password api, when user click on change password button */
export default class Verification {
  public apiResponse: APIResponse | undefined;
  constructor(public userInfo: Iverify) {}
  // Declaring the sub-path
  public validatePath: string = '/registration';
  // POST request for Confirm Registration and storing it to db. This methods returns a response body along with a status code.
  public async doValidate(): Promise<APIResponse> {
    try {
      const url: string = this.validateUrl(this.validatePath); // Creating the complete URL by combining the server URL and sub-path
      const requestBody: any = this.userInfo; // Getting the user information from the constructor
      const response: AxiosResponse<any, any> = await post(requestBody, url); // Making the POST request using Axios
      const errorMessage = '';
      const successMessage = '';
      // Handling the error response using the handleApiResponse function
      this.apiResponse = handleApiResponse(response.status, errorMessage, successMessage);
    } catch (error: any) {
      console.error(error);
      switch (error?.response?.status) {
        case 400:
          this.apiResponse = {
            statusCode: 400,
            body: 'User is already verified.'
          };
          break;
        case 409:
          this.apiResponse = {
            statusCode: 409,
            body: 'Code is not correct, Faild to verify.'
          };
          break;
        case 404:
          this.apiResponse = {
            statusCode: 404,
            body: 'User not found. Please check the email and try again.'
          };
          break;
        case 401:
          this.apiResponse = {
            statusCode: 401,
            body: 'Verification code has expired.'
          };
          break;
        case 500:
          this.apiResponse = {
            statusCode: 500,
            body: 'Failed to confirm user. Please try again later.'
          };
          break;
        default:
          this.apiResponse = { statusCode: 500, body: JSON.stringify(error) };
      }
    }
    return this.apiResponse;
  }

  // Binding the Webserver path and sub-path for Confirm registration
  private validateUrl(paramsValidate?: string): string {
    return backEndServerUrl + paramsValidate;
  }
}
