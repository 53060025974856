import React, { useContext, useEffect, useId, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../library/Button/button';
import LogoutConfirmationPopup from './LogoutConfirmationPopup';
import { LoginContext } from '../../context/IppContext';
import { createLogoutChannel } from '../../utils/Helper/broadcastChannel';
import ChangePassword from '../../pages/ChangePassword';
import NoticeIcon from '@rsuite/icons/Notice';
import CustomPopup from '../BtAdminPanel/CustomPopup';
import OrgUser from '../../utils/OrgUser/OrgUser';
import OrganizationAdmin from '../../utils/OrganizationAdmin/OrganizationAdmin';
import LockedUsersList from './LockedUsers';
import { removeDuplicates } from '../../utils/Helper/helper';
import IUserList from '../../utils/UserList/UserList.interface';
import { UserDetailContext } from '../../context/UserDetailContext';
import { onMessage } from 'firebase/messaging';
import { messaging } from '../../firebase';
import { Btadmin } from '../../utils/btAdmin';
import { routes } from '../../routes/Routes';

// interface to declare data types for props
interface ProfileProps {
  setIsLogout: React.Dispatch<React.SetStateAction<boolean>>;
  btAdmin?: boolean;
  isAdmin?: boolean;
  isUser?: boolean;
}
/* Profile component for Admin and BtAdmin which contains profile and logut section,as well as when user clicks on logout button it will show popup.
 props are used here to conditonally render background styles for BT logo and profile section. */
const Profile: React.FC<ProfileProps> = ({ setIsLogout, btAdmin, isAdmin, isUser }) => {
  const UserList: IUserList[] = [];
  let uniqueArray: IUserList[] | (() => IUserList[]) = [];

  const { updateToken, setTokenExpired } = useContext(LoginContext);
  const logoutChannel = createLogoutChannel();
  const navigate = useNavigate();
  const userEmail = sessionStorage.getItem('userEmail') as string;
  // Fetching the path for Admin and btAdmin page

  const uniqueId = useId();

  const [showNotification, setShowNotification] = useState(false);
  const [visibility, setVisibility] = useState<boolean>(false);
  // states are used to store values and show the form as per user inputs.
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [dataUser, setDataUser] = useState<IUserList[]>(uniqueArray);
  const { fullName, calculatedInitials } = useContext(UserDetailContext);

  // Clear the session after successfully logging user out
  const clearSession = async () => {
    sessionStorage.clear();
    updateToken('');
    // Notify other tabs to log out
    logoutChannel.postMessage('logout');
    const BtAdmin = new Btadmin();
    const response = await BtAdmin.updateActiveStatus(userEmail, false);
  };

  const popupCloseHandler = (e: boolean) => {
    setVisibility(e);
    setIsLogout(false);
  };

  const onLogoutHandler = () => {
    setVisibility(!visibility);
    setIsLogout(true);
  };

  const onCancelHandler = () => {
    setVisibility(!visibility);
    setIsLogout(false);
  };

  const onNotificationHandler = () => {
    setVisibility(true);
    setShowNotification(true);
    if (dataUser.length > 0) {
      setIsLogout(true);
    }
  };

  const onCloseHandler = (e: boolean) => {
    setVisibility(e);
    navigate(0);
  };
  // Function for Change password button.
  const handleChangeButtonClick = () => {
    setShowForm(!showForm);
    setIsLogout(true);
  };
  // function to Close the dropdown.
  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
      setShowForm(false);
    }
  };

  // function to show the change password form.
  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const checkIsLocked = (result: (IUserList | null)[]) => {
    for (let i = 0; i < result.length; i++) {
      if (result[i]?.isLocked) {
        UserList.push(result[i]!);
      }
    }
  };

  const getAdminOrganization = async () => {
    const organizationAdminObj = new OrganizationAdmin();
    const result = await organizationAdminObj.getAllOrganization();
    checkIsLocked(result);
    uniqueArray = removeDuplicates(UserList);
    setDataUser(uniqueArray);
  };

  const getAllUsers = async () => {
    const allUserObj = new OrgUser({ email: userEmail ?? '' });
    const result = await allUserObj.getAllUser(setTokenExpired);
    checkIsLocked(result);
    uniqueArray = removeDuplicates(UserList);
    setDataUser(uniqueArray);
  };

  onMessage(messaging, (payload) => {
    void getAdminOrganization();
    void getAllUsers();
  });
  useEffect(() => {
    const handleLogout = () => {
      setIsLogout(true);
      navigate(routes.login);
    };

    logoutChannel.addEventListener('message', handleLogout);

    // Clean up the event listener on component unmount
    return () => {
      logoutChannel.removeEventListener('message', handleLogout);
    };
  }, [logoutChannel, setIsLogout, navigate]);

  useEffect(() => {
    // Attach click event listener to the document
    document.addEventListener('click', handleClickOutside);
    return () => {
      // Remove the event listener when the component is unmounted
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  if (btAdmin) {
    useEffect(() => {
      void getAdminOrganization();
      void getAllUsers();
    }, []);
  }

  return (
    <div className="flex justify-between items-center text-black mr-2">
      {btAdmin && (
        <div>
          {/* <NotificationComponent/> */}
          <Button
            id={`bell-${uniqueId}`}
            aria-label="Bell"
            onclick={onNotificationHandler}
            className="disabled:cursor-not-allowed"
            buttonText={''}
            disabled={dataUser.length === 0}
          >
            <strong className="relative inline-flex items-center px-1 py-1.5 text-xs font-medium">
              <span className="absolute -top-1 -right-5 h-10 w-11 rounded-full bg-green-600 flex justify-center items-center items">
                {dataUser.length > 0 && (
                  <span className="text-red font-bold">{dataUser.length}</span>
                )}
              </span>
              <NoticeIcon
                height="40px"
                width="25px"
                fill={dataUser.length > 0 ? 'black' : '#5555'}
              />
            </strong>
          </Button>
          {showNotification && (
            <div>
              {dataUser.length > 0 && (
                <CustomPopup onClose={onCloseHandler} show={visibility} width={'w-41%'}>
                  <div>
                    <div className="text-[22px] text-center mb-[10px]">Locked Users</div>
                    <LockedUsersList lockedUsers={dataUser} />
                  </div>
                </CustomPopup>
              )}
            </div>
          )}
        </div>
      )}
      <div ref={dropdownRef}>
        <Button
          onclick={handleButtonClick}
          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded flex items-center"
          buttonText={''}
        >
          <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-white border border-gray-100 rounded-full">
            <span className="font-medium text-gray-100 dark:text-gray-300">
              {calculatedInitials}
            </span>
          </div>
          <svg
            className="w-4 h-4 text-gray-800 stroke-black dark:text-white ml-[7px] pr-[4px] mt-[5px]"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 8"
          >
            <path
              color="#000000"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"
            />
          </svg>
        </Button>
        {isOpen && (
          <div className="absolute z-50 mt-2 py-2 pl-[8px] pr-[8px] text-justify right-4 bg-white border border-gray-300 rounded shadow-lg">
            <div className="pl-[2px] pr-[4px] py-3 text-sm text-gray-100 dark:text-gray-300">
              <div className="truncate">{fullName}</div>
              <div className="font-medium truncate">
                {isAdmin ? 'Admin' : btAdmin ? 'Super Admin' : isUser ? 'Organisation User' : ''}
              </div>
            </div>
            <hr />
            <button
              className="block pl-[2px] pr-[4px] py-2 text-gray-800 hover:bg-gray-200 truncate"
              onClick={handleChangeButtonClick}
            >
              Change password
            </button>
            {showForm && <ChangePassword userEmail={userEmail} />}
            <Button
              className="block pl-[2px] pr-[4px] py-2 text-gray-800 hover:bg-gray-200"
              buttonText="Logout"
              onclick={onLogoutHandler}
            />
            <LogoutConfirmationPopup
              onClose={popupCloseHandler}
              visibility={visibility}
              onCancelHandler={onCancelHandler}
              clearSession={clearSession}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
