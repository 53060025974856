import React, { Fragment, useCallback, useEffect, useState } from 'react';
import CustomPopup from './CustomPopup';
import Button from '../../library/Button/button';
import IModuleList from '../../utils/ModuleList/ModuleList.interface';
import apiCallWrapper from '../../apiwrapper/apiCallWrapper';
import { ModuleAccessLevel } from '../../utils/Helper/enums';
import ConfirmationPopUp from '../../library/ConfirmationPopUp/ConfirmationPopUp';
import useNetworkConnection from '../../library/NetworkConnection/NetworkConnection';
import InputField from '../../library/inputField/inputField';
import { IPPFourthLevelHeading, IPPHeading, IPPParagraph } from '../../library/Heading/Heading';
import { IPreset } from '../../utils/ModulePresets/preset.interface';
import IUserList from '../../utils/UserList/UserList.interface';
import ConditionalLoader from '../ConditionalLoader';

/**
 * This common props takes in one parameter.
 * @organisationName - This prop is used for storing the organisation name.
 **/
interface AssignedModuleProps {
  organisationName: string | null;
  presets: IPreset[] | [];
  systemModules: IModuleList[];
  baseModules: IModuleList[];
  partner: IUserList;
  onChangePartner: (partner: IUserList) => void;
}

// This component is responsible for managing and displaying the assignment of access levels for various modules within an organization, allowing users to select access levels and submit the changes.
export const AssignedModuleForm: React.FC<AssignedModuleProps> = ({
  organisationName,
  presets,
  systemModules,
  baseModules,
  partner,
  onChangePartner
}) => {
  const backEndServerUrl = String(process.env.REACT_APP_BACK_END_SERVER_URL ?? '');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [submissionInProgress, setSubmissionInProgress] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>('Please Wait...');

  const [selectedAccessTypes, setSelectedAccessTypes] = useState<{ [moduleName: string]: string }>(
    {}
  );
  const [activeTab, setActiveTab] = useState<string>('Base Modules');
  const [defaultAccessTypes, setDefaultAccessTypes] = useState<{ [moduleName: string]: string }>(
    {}
  );
  const [selectedPreset, setselectedPreset] = useState<number>(-1);

  // Reusable hook to check internet connectivity
  const { isConnected } = useNetworkConnection();

  const selectPreset = (index: number): void => {
    setselectedPreset(index);
    if (index === -1) {
      setSelectedAccessTypes(defaultAccessTypes);
      return;
    }
    const selectedPresetValue = presets[index];
    const moduleList: any = {};
    selectedPresetValue?.moduleList?.forEach((element) => {
      moduleList[element.moduleName] = element.accessType;
    });
    setSelectedAccessTypes((prvs) => {
      systemModules.forEach((module: any) => {
        moduleList[module.moduleName] = prvs[module.moduleName] ?? ModuleAccessLevel.NOACCESS;
      });
      return moduleList;
    });
  };

  const handleRadioButtonChange = useCallback(
    (moduleName: string, accessType: ModuleAccessLevel, isSystemModule?: boolean) => {
      setSelectedAccessTypes((prevAccessTypes) => {
        const newAccessTypes = {
          ...prevAccessTypes,
          [moduleName]: accessType
        };
        if (!isSystemModule) {
          setselectedPreset(-1);
        }
        return newAccessTypes;
      });
    },
    [defaultAccessTypes]
  );

  const isPartialAccessSelected = (moduleName: string) => {
    return selectedAccessTypes[moduleName] === ModuleAccessLevel.PARTIALACCESS;
  };

  const isFullAccessSelected = (moduleName: string) => {
    return selectedAccessTypes[moduleName] === ModuleAccessLevel.FULLACCESS;
  };

  const handleButtonClick = useCallback(() => {
    setShowLoader(false);
    setShowAlert(false);
    setShowForm(true);
  }, []);

  const switchTab = useCallback((tab: string) => {
    setActiveTab(tab);
  }, []);

  const submitModule = (): void => {
    setSubmissionInProgress(true);
    setShowLoader(true);
    setTimeout(() => {
      setShowLoader(false);
      setShowAlert(true);
      setSubmissionInProgress(false);

      // Filter out modules with "No Access" from the selectedAccessTypes object
      const filteredAccessTypes = Object.keys(selectedAccessTypes)
        .filter((moduleName) => selectedAccessTypes[moduleName] !== ModuleAccessLevel.NOACCESS)
        .reduce<{ [moduleName: string]: string }>((obj, moduleName) => {
          obj[moduleName] = selectedAccessTypes[moduleName];
          return obj;
        }, {});

      const moduleArray = Object.entries(filteredAccessTypes).map(([moduleName, accessType]) => ({
        moduleName,
        accessType
      }));

      const presetName = selectedPreset !== -1 ? presets[selectedPreset].presetName : '';
      const formattedData = {
        orgname: organisationName,
        moduleList: moduleArray,
        presetName
      };

      const subPath = '/module/assigneModule';
      const axiosWithRoles = apiCallWrapper(subPath, 'POST');
      axiosWithRoles
        .post(`${backEndServerUrl}/module/assigneModule`, formattedData)
        .then((response) => {
          const responseBody = 'Module Assigned Successfully!!';
          onChangePartner({
            ...partner,
            moduleList: moduleArray,
            module: moduleArray.length.toString(),
            presetName
          });
          setResponseMessage(responseBody);
          // Update default access types after successful submission
          setDefaultAccessTypes(selectedAccessTypes);
        })
        .catch((error) => {
          throw error;
        });
    }, 20000);
  };

  const popupCloseAlertHandler = (e: boolean) => {
    setShowAlert(e);
    setShowForm(e);
  };

  const closeModal = () => {
    setSelectedAccessTypes(defaultAccessTypes);
    setShowForm(false);
    setShowAlert(false);
    setShowConfirmation(false);
    if (partner.presetName) {
      const accessPreset = presets.findIndex(
        (preset) => preset.presetName.trim() === partner.presetName?.trim()
      );
      setselectedPreset(accessPreset);
    }
  };

  const openModal = () => {
    setselectedPreset(selectedPreset);
    setShowForm(true);
    setShowAlert(false);
    setShowConfirmation(false);
  };

  const popupCloseHandler = useCallback(
    (e: boolean) => {
      if (!submissionInProgress) {
        let hasChanges = false;
        for (const moduleName in selectedAccessTypes) {
          const currentAccessType = selectedAccessTypes[moduleName];
          const defaultAccessType = defaultAccessTypes[moduleName];
          const isChangedFromDefault =
            defaultAccessType !== undefined
              ? currentAccessType !== defaultAccessType
              : currentAccessType !== ModuleAccessLevel.NOACCESS;
          if (isChangedFromDefault) {
            hasChanges = true;
            break;
          }
        }
        if (hasChanges) {
          setShowForm(e);
          setShowConfirmation(true);
        } else {
          if (partner.presetName) {
            const accessPreset = presets.findIndex(
              (preset) => preset.presetName.trim() === partner.presetName?.trim()
            );
            setselectedPreset(accessPreset);
          }
          setShowForm(false);
        }
      } else {
        setShowForm(false); // If submission is in progress, close the form without showing the confirmation
      }
    },
    [defaultAccessTypes, selectedAccessTypes, submissionInProgress]
  );

  useEffect(() => {
    const updateUi = () => {
      partner?.moduleList?.forEach((selectedModule: any) => {
        const accessType = selectedModule.accessType;

        setSelectedAccessTypes((prevAccessTypes) => ({
          ...prevAccessTypes,
          [selectedModule.moduleName]: accessType
        }));

        setDefaultAccessTypes((prevDefaultAccessTypes) => ({
          ...prevDefaultAccessTypes,
          [selectedModule.moduleName]: accessType
        }));
      });
      if (partner.presetName) {
        const accessPreset = presets.findIndex(
          (preset) => preset.presetName.trim() === partner.presetName?.trim()
        );
        setselectedPreset(accessPreset);
      }
      setIsLoading(false);
    };

    void updateUi();
  }, [partner, presets]);

  return (
    <Fragment>
      {isConnected && (
        <div>
          <Button
            className="text-white bg-black rounded-md text-xs py-1 px-4 ml-2"
            buttonText="Access"
            onclick={handleButtonClick}
          />
          {showForm && (
            <CustomPopup
              onClose={popupCloseHandler}
              show={showForm}
              width={'w-full sm:min-w-[80%] md:max-w-fit lg:min-w-fit'}
            >
              <div className="flex start justify-between px-4 py-4 pt-[0rem]">
                <IPPFourthLevelHeading
                  headerText={'Assign Modules'}
                  className={'mb-0 leading-normal text-2xl text-black'}
                />
              </div>
              <br />
              <ConditionalLoader isLoading={isLoading} className="my-16" width={350}>
                <div className="max-w-full overflow-hidden overflow-x-scroll">
                  <div className="flex justify-start gap-2">
                    <div className="min-w-fit max-h-[34rem] border-r border-black">
                      <div className="w-full inline-block text-black rounded-t-lg lg:w-[18rem] py-4 px-4 text-[18px] text-center border-b border-black">
                        <p>Presets</p>
                      </div>
                      <div className="flex flex-col gap-2 items-center justify-center pt-2 max-h-[30rem] overflow-hidden overflow-y-auto">
                        <Button
                          className={`inline-block mb-0 h-[50px] text-[17px] w-full cursor-pointer rounded-md px-4 py-2 ${
                            selectedPreset === -1 && 'bg-gray text-black'
                          }`}
                          buttonText="Custom"
                          onclick={() => {
                            selectPreset(-1);
                          }}
                        />
                        {presets?.map((preset, index) => (
                          <Button
                            key={preset.presetName + index}
                            className={`inline-block mb-0 h-[50px] text-[17px] w-full cursor-pointer rounded-md px-4 py-2 ${
                              selectedPreset === index && 'bg-gray text-black'
                            }`}
                            buttonText={preset.presetName}
                            onclick={() => {
                              selectPreset(index);
                            }}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="flex-grow">
                      <div className="border-b border-black">
                        <div
                          className={
                            activeTab === 'Base Modules'
                              ? 'inline-block text-black rounded-t-lg lg:w-[18rem] py-4 px-4 text-[18px] text-center active bg-gray cursor-pointer'
                              : 'inline-block text-black rounded-t-lg lg:w-[18rem] py-4 px-4 text-[18px] text-center cursor-pointer'
                          }
                          onClick={() => switchTab('Base Modules')}
                        >
                          Base Modules
                        </div>
                        <div
                          className={
                            activeTab === 'Custom Modules'
                              ? 'inline-block text-black rounded-t-lg lg:w-[18rem] py-4 px-4 text-[18px] text-center active bg-gray cursor-pointer'
                              : 'inline-block text-black rounded-t-lg lg:w-[18rem] py-4 px-4 text-[18px] text-center cursor-pointer'
                          }
                          onClick={() => switchTab('Custom Modules')}
                        >
                          Custom Modules
                        </div>
                      </div>
                      <div>
                        {activeTab === 'Base Modules' && baseModules.length > 0 ? (
                          <div className="position-relative grow shrink basis-auto p-[1rem]block max-h-[30rem] overflow-y-auto">
                            <table className="w-full border-separate border-spacing-y-[3px]">
                              <thead className="table-header-group align-middle bg-[#f8f8f8] sticky-header">
                                <tr className="text-black text-left table-row align-inherit">
                                  <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                                    Module Name
                                  </th>
                                  <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                                    Full Access
                                  </th>
                                  <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                                    Partial Access
                                  </th>
                                  <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                                    No Access
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {baseModules
                                  .sort((a: { moduleName: string }, b: { moduleName: any }) =>
                                    a.moduleName.localeCompare(b.moduleName)
                                  )
                                  .map((element: IModuleList, index: number) => (
                                    <tr key={index}>
                                      <td
                                        className={`px-6 py-4 text-center ${
                                          (selectedAccessTypes[element.moduleName] !==
                                            ModuleAccessLevel.NOACCESS ||
                                            defaultAccessTypes[element.moduleName] !== undefined) &&
                                          selectedAccessTypes[element.moduleName] !==
                                            defaultAccessTypes[element.moduleName]
                                            ? 'bg-gray rounded-[50px]'
                                            : ''
                                        }`}
                                      >
                                        {element.moduleName}
                                      </td>
                                      <td className="px-6 py-4 text-center">
                                        <div className="flex items-center ml-[2.2rem]">
                                          <InputField
                                            id={`full-access-radio-${index}`}
                                            type="radio"
                                            name={element.moduleName}
                                            className={
                                              'w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 accent-black'
                                            }
                                            checked={isFullAccessSelected(element.moduleName)}
                                            onChange={() =>
                                              handleRadioButtonChange(
                                                element.moduleName,
                                                ModuleAccessLevel.FULLACCESS
                                              )
                                            }
                                          />
                                        </div>
                                      </td>
                                      <td className="px-6 py-4 text-center">
                                        <div className="flex items-center ml-[3rem]">
                                          <InputField
                                            id={`partial-access-radio-${index}`}
                                            type="radio"
                                            name={element.moduleName}
                                            className={
                                              'w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 accent-black'
                                            }
                                            checked={isPartialAccessSelected(element.moduleName)}
                                            onChange={() =>
                                              handleRadioButtonChange(
                                                element.moduleName,
                                                ModuleAccessLevel.PARTIALACCESS
                                              )
                                            }
                                          />
                                        </div>
                                      </td>
                                      <td className="px-6 py-4 text-center">
                                        <div className="flex items-center ml-[2rem]">
                                          <InputField
                                            id={`no-access-radio-${index}`}
                                            type="radio"
                                            name={element.moduleName}
                                            className={
                                              'w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 accent-black'
                                            }
                                            checked={
                                              selectedAccessTypes[element.moduleName] ===
                                                ModuleAccessLevel.NOACCESS ||
                                              (!isPartialAccessSelected(element.moduleName) &&
                                                !isFullAccessSelected(element.moduleName))
                                            }
                                            onChange={() =>
                                              handleRadioButtonChange(
                                                element.moduleName,
                                                ModuleAccessLevel.NOACCESS
                                              )
                                            }
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          systemModules.length === 0 && (
                            <div className="px-6 py-4 text-center pt-20 font-bold">
                              <IPPHeading
                                headerText={'No data found ☹️ !!'}
                                className={'text-bold'}
                              />
                            </div>
                          )
                        )}
                        {activeTab === 'Custom Modules' && systemModules.length > 0 ? (
                          <div className="position-relative grow shrink basis-auto p-[1rem]block max-h-[30rem] overflow-y-auto">
                            <table className="w-100% border-collapse">
                              <thead className="table-header-group align-middle bg-[#f8f8f8] sticky-header">
                                <tr className="text-black text-left table-row align-inherit">
                                  <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                                    Module Name
                                  </th>
                                  <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                                    Full Access
                                  </th>
                                  <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                                    Partial Access
                                  </th>
                                  <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                                    No Access
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {systemModules
                                  .sort((a: { moduleName: string }, b: { moduleName: any }) =>
                                    a.moduleName.localeCompare(b.moduleName)
                                  )
                                  .map((module: IModuleList, index: number) => (
                                    <tr key={index}>
                                      <div className="mt-[5px]">
                                        <td
                                          className={`px-10 py-4 text-center ${
                                            (selectedAccessTypes[module.moduleName] !==
                                              ModuleAccessLevel.NOACCESS ||
                                              defaultAccessTypes[module.moduleName] !==
                                                undefined) &&
                                            selectedAccessTypes[module.moduleName] !==
                                              defaultAccessTypes[module.moduleName]
                                              ? 'bg-gray rounded-[50px]'
                                              : ''
                                          }`}
                                        >
                                          {module.moduleName}
                                        </td>
                                      </div>
                                      <td className="px-6 py-4 text-center">
                                        <div className="flex items-center ml-[2.2rem]">
                                          <InputField
                                            id={`full-access-radio-${index}`}
                                            type="radio"
                                            name={module.moduleName}
                                            className={
                                              'w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 accent-black'
                                            }
                                            checked={isFullAccessSelected(module.moduleName)}
                                            onChange={() =>
                                              handleRadioButtonChange(
                                                module.moduleName,
                                                ModuleAccessLevel.FULLACCESS,
                                                true
                                              )
                                            }
                                          />
                                        </div>
                                      </td>
                                      <td className="px-6 py-4 text-center">
                                        <div className="flex items-center ml-[3rem]">
                                          <InputField
                                            id={`partial-access-radio-${index}`}
                                            type="radio"
                                            name={module.moduleName}
                                            className={
                                              'w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 accent-black'
                                            }
                                            checked={isPartialAccessSelected(module.moduleName)}
                                            onChange={() =>
                                              handleRadioButtonChange(
                                                module.moduleName,
                                                ModuleAccessLevel.PARTIALACCESS,
                                                true
                                              )
                                            }
                                          />
                                        </div>
                                      </td>
                                      <td className="px-6 py-4 text-center">
                                        <div className="flex items-center ml-[2rem]">
                                          <InputField
                                            id={`no-access-radio-${index}`}
                                            type="radio"
                                            name={module.moduleName}
                                            className={
                                              'w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 accent-black'
                                            }
                                            checked={
                                              selectedAccessTypes[module.moduleName] ===
                                                ModuleAccessLevel.NOACCESS ||
                                              (!isPartialAccessSelected(module.moduleName) &&
                                                !isFullAccessSelected(module.moduleName))
                                            }
                                            onChange={() =>
                                              handleRadioButtonChange(
                                                module.moduleName,
                                                ModuleAccessLevel.NOACCESS,
                                                true
                                              )
                                            }
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          baseModules.length === 0 && (
                            <div className="px-6 py-4 text-center pt-20 font-bold">
                              <IPPHeading
                                headerText={'No data found ☹️ !!'}
                                className={'text-bold'}
                              />
                            </div>
                          )
                        )}
                        {showAlert && (
                          <CustomPopup
                            onClose={popupCloseAlertHandler}
                            show={showAlert}
                            width={'w-41%'}
                          >
                            <div className="max-h-30% ">
                              <IPPParagraph
                                headerText={`${responseMessage}`}
                                className={'mr-0 mb-0.5 ml-0 text-[17px]'}
                              />
                            </div>
                          </CustomPopup>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-white p-4 pb-0 inset-x-0 mt-4">
                  <Button
                    className="inline-block mb-0 h-[42px] text-[#fff] text-[17px] w-[9rem] cursor-pointer bg-black rounded-md px-4 py-2"
                    buttonText="Submit"
                    onclick={submitModule}
                    loading={showLoader}
                    disabled={showLoader}
                  />
                </div>
              </ConditionalLoader>
            </CustomPopup>
          )}
          {showConfirmation && (
            <ConfirmationPopUp show={showConfirmation}>
              <div className="max-h-30% ">
                <IPPParagraph
                  headerText={'You have made changes. Do you want to discard?'}
                  className={'mr-0 mb-0.5 ml-0 text-[23px]'}
                />
                <br />
                <div className="flex justify-center">
                  <Button
                    className="h-10 px-4 py-[1.75] bg-white hover:bg-black hover:text-white text-[#2c323f] rounded focus:outline-none border border-spacing-1 border-black mr-3"
                    buttonText="Yes"
                    onclick={closeModal}
                  />
                  <Button
                    className="px-4 py-[1.75] bg-white hover:bg-black hover:text-white text-[#2c323f] rounded focus:outline-none border border-spacing-1 border-black ml-3"
                    buttonText="No"
                    onclick={openModal}
                  />
                </div>
              </div>
            </ConfirmationPopUp>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default AssignedModuleForm;
