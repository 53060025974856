import React, { useState } from 'react';
import Label from '../../library/label/label';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import { domainRegex } from '../../utils/regex';
interface PartnerOrganisationDomainsProps {
  domains: string[];
  onOrganisationDomainChange: (domain: string[]) => void;
}

const PartnerOrganisationDomains: React.FC<PartnerOrganisationDomainsProps> = ({
  domains,
  onOrganisationDomainChange
}) => {
  const [isValidDomain, setIsValidDomain] = useState<boolean>(true);

  const onDomainChange = (_domains: string[]) => {
    onOrganisationDomainChange(_domains.map((item) => item.toLowerCase()));
  };

  const validateDomain = (domain: string) => {
    if (domain === 'undefined') {
      return false;
    }
    return domainRegex.test(domain);
  };

  const handleInputChange = (value: string) => {
    if (value === '') {
      setIsValidDomain(true);
    } else {
      setIsValidDomain(validateDomain(value));
    }
  };

  return (
    <div className="block ">
      <div className="p-0 w-full float-left relative">
        <div className="w-full my-5 mx-0 relative table border-separate ">
          <Label
            className="text-right leading-[40px] pr-2.5 pl-0 w-fit sm:w-[31.25%] mb-[5px] float-left whitespace-nowrap sm:whitespace-normal"
            LabelText="Partner company domain:"
          />

          <div className="px-0 w-full sm:w-[31.25%] min-w-[100px] sm:min-w-[220px] max-w-[362px] table">
            <ReactMultiEmail
              placeholder="Input your domain"
              emails={domains}
              onChange={onDomainChange}
              autoFocus={true}
              validateEmail={validateDomain}
              onChangeInput={handleInputChange}
              className={`${!isValidDomain ? 'border !border-red' : ''}`}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div
                    className="cursor-pointer"
                    data-tag
                    key={index}
                    onClick={() => removeEmail(index)}
                  >
                    <div data-tag-item>{email}</div>
                  </div>
                );
              }}
            />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};
export default PartnerOrganisationDomains;
