import React, { createContext, useState } from 'react';

export interface IUserDetails {
  fullName?: string;
  firstName?: string;
  calculatedInitials?: string;
}

interface IUserDetailProvider {
  children: React.ReactNode;
  value: IUserDetails;
}

const UserDetailContext = createContext<IUserDetails>({
  fullName: '',
  firstName: '',
  calculatedInitials: ''
});

const UserDetailProvider: React.FC<IUserDetailProvider> = ({ children, value }) => {
  return <UserDetailContext.Provider value={value}>{children}</UserDetailContext.Provider>;
};

export { UserDetailProvider, UserDetailContext };
