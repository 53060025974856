import React, { useCallback } from 'react';
import { ModuleAccessLevel } from '../../utils/Helper/enums';

interface Props {
  name: string;
  accessType: string;
  disabled: boolean;
}

const ModuleRow = ({ name, accessType, disabled }: Props) => {
  const bgColor = (accessType: string) => {
    if (accessType === ModuleAccessLevel.FULLACCESS) {
      return 'bg-[#28a745]';
    } else if (accessType === ModuleAccessLevel.PARTIALACCESS) {
      return 'bg-[#ffc107]';
    }
    return 'bg-gray';
  };

  return (
    <div
      key={name}
      className="bg-white pt-6 rounded-lg shadow-md hover:shadow-xl transition-all transform hover:scale-105 text-ellipsis flex flex-col justify-between overflow-hidden"
    >
      <div className="p-6 pt-0">
        <h3 className="text-xl text-black font-semibold mb-2">{name}</h3>
        <p className="text-gray-600 text-lg italic">{accessType}</p>
      </div>
      <div className={`h-[10px] w-full ${bgColor(accessType)}`}></div>
    </div>
  );
};

export default ModuleRow;
