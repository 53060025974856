import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Loader: React.FC<{}> = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  }, []);
  return (
    <>
      {isLoading && (
        <div className="absolute bg-white bg-opacity-80 h-full w-full flex items-center justify-center z-50">
          <div className="flex items-center">
            <div className="absolute animate-spin rounded-full h-[9rem] w-32 border-t-4 border-b-4 border-purple" />
            <img src="/avatar-thinking-1.svg" className="rounded-full h-28 w-28" />
          </div>
        </div>
      )}
    </>
  );
};
export default Loader;
