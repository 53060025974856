import React, { useEffect, useState } from 'react';

const useTimeOfDay = () => {
  const [timeOfDay, setTimeOfDay] = useState<string>('');
  useEffect(() => {
    const hour = new Date().getHours();
    if (hour >= 5 && hour < 12) {
      setTimeOfDay('Good morning');
    } else if (hour >= 12 && hour < 18) {
      setTimeOfDay('Good afternoon');
    } else {
      setTimeOfDay('Good evening');
    }
  }, []);
  return timeOfDay;
};

export default useTimeOfDay;
