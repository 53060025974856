import React, { useState, Fragment, useEffect } from 'react';
import CustomPopup from '../BtAdminPanel/CustomPopup';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import OrgUser from '../../utils/OrgUser/OrgUser';
import IUserList from '../../utils/UserList/UserList.interface';
import Form from '../../library/Form/Form';
import { handleApiResponse } from '../../utils/Helper/apiResponseHandler';
import Button from '../../library/Button/button';
import { JobTitle, responseMessages } from '../../utils/Helper/enums';
import { adminRoutes } from '../../routes/Routes';

interface adminUserProps {
  organisationName: string | null;
  organisationEmail: string | null;
  organisationDomain?: string[] | null;
  callback?: () => void;
}
// Re-usable component for creating Organization users for a specific Organization Admin
export const CreateUserForm: React.FC<adminUserProps> = ({
  organisationName,
  organisationEmail,
  organisationDomain,
  callback
}) => {
  const optionJobTitle = [
    { label: '', value: '' },
    { label: JobTitle.Developer, value: JobTitle.Developer },
    { label: JobTitle.Admin, value: JobTitle.Admin }
  ];
  // ToDO: In later stages, we're planning to handle it from the popup.

  const [showForm, setShowForm] = useState<boolean>(false);
  const [orgName, setOrgName] = useState(organisationName);
  const [orgEmail, setOrgEmail] = useState(organisationEmail);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [role, setRole] = useState<string>('');
  const [showLoader, setShowLoader] = useState(false);
  const [responseMessage, setResponseMessage] = useState<string>('');
  const [showAlert, setShowAlert] = useState(false);
  const [phone, setPhone] = useState<string>('');
  const [domain, setDomain] = useState<string>('');

  const navigate = useNavigate();

  useEffect(() => {
    setOrgName(organisationName);
    setOrgEmail(organisationEmail);
  }, [organisationEmail, organisationName]);

  useEffect(() => {
    // Reset form fields when opening form
    if (showForm) {
      setOrgName(organisationName);
      setOrgEmail(organisationEmail);
      setName('');
      setEmail('');
      setRole('');
      setPhone('');
      setShowAlert(false);
    }
  }, [showForm]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  // Storing data from UI to db via promise
  const createUser: any = async (data: IUserList) => {
    const emailInput = data.email?.toLowerCase() ?? '';
    const orgDomain = emailInput?.split('@')[1] ?? '';
    if (organisationDomain!.length <= 0) {
      createUserApiCall(data);
    } else {
      if (organisationDomain?.includes(orgDomain)) {
        createUserApiCall(data);
      } else {
        alert('Sorry, you can only use organisational domains to create an user.');
      }
    }
  };

  const createUserApiCall: any = async (data: IUserList) => {
    const emailInput = data.email?.trim().toLowerCase() ?? '';
    const nameInput = data.name?.trim() ?? '';
    setShowLoader(true);
    setTimeout(() => setShowForm(false), 20000);
    // form submission code here
    const userObj = new OrgUser({
      email: emailInput,
      orgEmail,
      orgName,
      name: nameInput,
      role,
      phone
    });

    // API responses have been merged since actions are same
    const createStatus = await userObj.getAllOrgUser();
    const errorMessage = responseMessages.UserExists;
    const successMessage = responseMessages.CreateUserSuccess;
    const response = handleApiResponse(createStatus.statusCode, errorMessage, successMessage);
    const responseBody = response.body;
    setResponseMessage(responseBody);
    setShowAlert(true);

    reset();

    if (createStatus.statusCode === 201) {
      if (callback) {
        callback();
      }
      navigate(adminRoutes.dashoard);
    }
    setShowLoader(false);
  };

  // Method to show the create user form on clicking the add button
  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  // Method to close the create user form on clicking the close button
  const popupCloseHandler = (e: boolean) => {
    setShowForm(e);
    reset();
  };

  // utility function for async react callback properties when void expected
  function wrapAsyncFunction<ARGS extends unknown[]>(
    fn: (...args: ARGS) => Promise<unknown>
  ): (...args: ARGS) => void {
    return (...args) => {
      void fn(...args);
    };
  }
  return (
    <Fragment>
      <Button
        className="mb-0 text-white px-6 py-2 cursor-pointer bg-black rounded-lg"
        buttonText="Create User"
        onclick={handleButtonClick}
      />
      {showForm && (
        <CustomPopup onClose={popupCloseHandler} show={showForm} width={'w-41%'}>
          <Form
            register={register}
            role={role}
            setRole={setRole}
            organisationName={orgName}
            organisationEmail={orgEmail}
            optionJobTitle={optionJobTitle}
            createUser={wrapAsyncFunction(handleSubmit(createUser))}
            show={showAlert}
            setShowForm={setShowForm}
            message={responseMessage}
            showLoader={showLoader}
            errors={errors}
            setPhone={setPhone}
            phone={phone}
            setName={setName}
            setEmail={setEmail}
            isBtAdmin={true}
            usersEmail={''}
            optionDomain={organisationDomain}
            setDomain={setDomain}
            domain={domain}
            onSubmit={function (e: React.FormEvent<Element>): void {
              throw new Error('Function not implemented.');
            }}
          />
        </CustomPopup>
      )}
    </Fragment>
  );
};
