import React, { useEffect, useState } from 'react';
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';
import CustomPopup from '../components/BtAdminPanel/CustomPopup';
import ResetPassword from '../utils/PasswordRsest/resetPassword';
import SendVerificationCode from '../utils/PasswordRsest/sendVerificationCode';
import { wrapAsyncFunction } from './UtilityFunction/wrapAsyncFunction';
import InputField from '../library/inputField/inputField';
import Label from '../library/label/label';
import Button from '../library/Button/button';
import { routes } from '../routes/Routes';
// this component is responsible to verify user using otp while reseting password.
interface SubmitCodeProps {
  email: string | any;
  newPassword: string | any;
}
const SubmitCode: React.FC<SubmitCodeProps> = ({ email, newPassword }) => {
  const [confirmationCode, setConfirmationCode] = useState('');
  const [codeStatus, setCodeStatus] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [responseMessage, setResponseMessage] = useState<string>('Please Wait...');
  const navigate: NavigateFunction = useNavigate();
  const [userEmail, setUserEmail] = useState(email);
  // UseRffect hook to avoid unnecessary component rendering
  useEffect(() => {
    setUserEmail(email);
  }, []);
  // Method to set entered code.
  const handleVerificationCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmationCode(e.target.value);
  };
  // This Method is responsibe to reset the password.
  const handleFormSubmit = async () => {
    setShowAlert(true);
    const resetPasswordObj = new ResetPassword({
      email: userEmail,
      confirmationCode,
      newPassword
    });
    try {
      const resetStatus = await resetPasswordObj.resetPassword();
      switch (resetStatus.statusCode) {
        case 201:
          setResponseMessage('Password reset successful');
          break;
        case 400:
          setResponseMessage(resetStatus.body);
          break;
        case 401:
          setResponseMessage(resetStatus.body);
          break;
        default:
          setResponseMessage('Failed to reset the password');
          break;
      }
      if (resetStatus.statusCode === 201) {
        setCodeStatus(true);
      }
    } catch (error) {
      setResponseMessage('An error occurred while resetting the password.');
    } finally {
      setShowAlert(true);
    }
  };

  // Method to close popup.
  const popupCloseAlertHandler = (e: boolean) => {
    setShowAlert(false);
    setResponseMessage('Please wait');
    if (codeStatus) {
      navigate(routes.login);
    }
  };
  const isVerificationCodeEmpty = confirmationCode.trim() === '';
  const isFormValid = !isVerificationCodeEmpty;
  // Method for resend code to useremail
  const handleResendCode = async () => {
    setShowAlert(true);
    const sendVerificationCodeObj = new SendVerificationCode({
      email: userEmail ?? ''
    });
    try {
      const sendCodeStatus = await sendVerificationCodeObj.sendCode();
      switch (sendCodeStatus.statusCode) {
        case 201:
          setResponseMessage('Sent code successfully');
          break;
        case 400:
          setResponseMessage(sendCodeStatus.body);
          break;
        case 404:
          setResponseMessage(sendCodeStatus.body);
          break;
        case 429:
          setResponseMessage(sendCodeStatus.body);
          break;
        default:
          setResponseMessage('Failed to send code');
          break;
      }
      if (sendCodeStatus.statusCode !== 201) {
        navigate(routes.login);
      }
    } catch (error) {
      setResponseMessage('An error occurred while resending the verification code.');
    } finally {
      setShowAlert(true);
    }
  };
  const handleKeyUp = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && isFormValid) {
      event.preventDefault();
      await handleFormSubmit();
    }
  };
  return (
    <div className="w-72 p-2">
      <Label
        htmlFor="verificationCode"
        className="text-xl block text-center mb-2 mt-2"
        LabelText="Verification Code"
      />

      <InputField
        id={'verificationCode'}
        className="h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
        type="number"
        placeholder="Enter your verification code"
        value={confirmationCode}
        onChange={handleVerificationCodeChange}
        onKeyUp={handleKeyUp}
      />
      <Button
        className={
          isFormValid
            ? 'bg-black  text-white border-black py-[11px] px-5 my-[5px] mx-0 w-full text-center h-10 text-[.9em] rounded-md inline-block'
            : 'bg-[#808080] cursor-not-allowed  text-white border-[#808080] opacity-50 py-[11px] px-5 my-[5px] mx-0 w-full text-center h-10 text-[.9em] rounded-md inline-block'
        }
        disabled={!isFormValid}
        onclick={wrapAsyncFunction(handleFormSubmit)}
        buttonText="Submit"
      />
      <p className="mb-2 mt-2 text-center clear-both text-sm pb-0 m-0 text-[#666]">
        <Link
          className="text-sm text-black underline"
          onClick={wrapAsyncFunction(handleResendCode)}
          to={' '}
        >
          Resend verification code{' '}
        </Link>
        &nbsp;
      </p>
      {showAlert && (
        <CustomPopup onClose={popupCloseAlertHandler} show={showAlert} width={'w-41%'}>
          <div className="max-h-30%">
            <p className="mr-0 mb-0.5 ml-0 text-[17px]">{responseMessage}</p>
          </div>
        </CustomPopup>
      )}
    </div>
  );
};

export default SubmitCode;
