import React, { useState } from 'react';
import Button from './button';

interface Props {
  id: string;
  text?: string;
  onClick: () => void;
  children: React.ReactNode;
}

const TooltipButton = ({ text, onClick, children, id }: Props) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  return (
    <Button
      id={id}
      aria-label="Edit Organisation"
      className="bg-transparent"
      onclick={onClick}
      onMouseEnter={() => setIsTooltipVisible(true)}
      onMouseLeave={() => setIsTooltipVisible(false)}
    >
      {children}
      {isTooltipVisible && text && (
        <div className="group flex relative">
          <span
            className="px-1 py-1 group-hover:opacity-100 transition-opacity text-sm text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800 absolute -top-24 -left-3/4
        -translate-x-1/2 translate-y-full m-4 mx-auto"
          >
            {text}
          </span>
        </div>
      )}
    </Button>
  );
};

export default TooltipButton;
