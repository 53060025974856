import React from 'react';
import './App.css';

import { Routes, Route } from 'react-router-dom';
import { ProtectedRoutes } from './components/ProtectedRoute';
import LoginContextProvider from './context/LoginContextprovider';

import ConfirmRegistration from './pages/ConfirmRegistration';
import Dashboard from './pages/Dashboard';
import Register from './pages/Register';
import Login from './pages/Login';
import AdminPanel from './pages/AdminPanel';
import BtAdminPanel from './pages/BtAdmin/BtAdminPanel';
import UserPanel from './pages/UserPanel';
import NotFoundPage from './pages/NotFoundPage';
import ForgotPassword from './pages/ForgotPassword';
import PasswordSetup from './pages/PasswordSetup';
import Mfa from './pages/Mfa';
import TermsAndConditions from './pages/TermsAndConditions';
import { CompanyNameProvider } from './context/CompanyNameContext';
import AuditScreen from './pages/BtAdmin/AuditScreen';
import PresetModules from './pages/BtAdmin/PresetModules';
import BtAdminPanelLayout from './layout/BtAdminPanelLayout';
import PartnerAdmin from './pages/BtAdmin/PartnerAdmin';
import Analytics from './pages/BtAdmin/Analytics';
import Modules from './pages/BtAdmin/Modules';
import { adminRoutes, btAdminRoutes, routes, userRoutes } from './routes/Routes';
import PartnerPanelLayout from './layout/PartnerPanelLayout';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('./firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
}

function App(): any {
  return (
    <LoginContextProvider>
      <CompanyNameProvider>
        <Routes>
          <Route path={routes.root} index={true} element={<Dashboard />} />
          <Route path={routes.login} element={<Login />} />
          <Route path={routes.termsAndConditions} element={<TermsAndConditions />} />
          <Route path={routes.mfa} element={<Mfa />} />
          <Route element={<ProtectedRoutes />}>
            <Route element={<PartnerPanelLayout isAdmin={true} />}>
              <Route path={adminRoutes.dashoard} element={<AdminPanel />} />
            </Route>
            <Route element={<BtAdminPanelLayout />}>
              <Route path={btAdminRoutes.dashboard} element={<BtAdminPanel />} />
              <Route path={btAdminRoutes.partnerAdmin} element={<PartnerAdmin />} />
              <Route path={btAdminRoutes.modules} element={<Modules />} />
              <Route path={btAdminRoutes.analytics} element={<Analytics />} />
              <Route path={btAdminRoutes.audit} element={<AuditScreen />} />
              <Route path={btAdminRoutes.presets} element={<PresetModules allModules={[]} />} />
            </Route>
            <Route path={btAdminRoutes.register} element={<Register />} />
            <Route element={<PartnerPanelLayout />}>
              <Route path={userRoutes.dashboard} element={<UserPanel />} />
            </Route>
          </Route>
          <Route path={routes.otp} element={<ConfirmRegistration />} />
          <Route path={routes.resetPassword} element={<ForgotPassword />}></Route>
          <Route path={routes.setupPassword} element={<PasswordSetup />}></Route>
          <Route path={routes.default} element={<NotFoundPage />} />
        </Routes>
      </CompanyNameProvider>
    </LoginContextProvider>
  );
}

export default App;
