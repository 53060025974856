import React from 'react';
import { useLocation } from 'react-router-dom';

function Footer(props: any): any {
  const clientVersion = process.env.REACT_APP_VERSION ?? '0.0.0';
  const DisplayableClientVersion = String('v' + clientVersion);
  const location = useLocation();
  return (
    <div className="h-28 w-full min-h-fit flex items-end bg-black pl-4 pb-2 z-40">
      <div className="w-full h-fit flex flex-col md:flex-row md:gap-36 lg:gap-40 pb-2">
        <p className="text-white pt-4">© BT Group plc</p>
        <p className="text-white pt-4">{DisplayableClientVersion}</p>
      </div>
    </div>
  );
}

export default Footer;
