import React, { ReactNode, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

/**
 * This component is designed to create a pop-up/modal dialog with a confirmation message.
 * @useState - This hook is used to manage the show state, which determines whether the pop-up should be visible or hidden. The initial state is set to false by default.
 * @useEffect - This hook is utilized to update the show state based on the props.show value. It listens to changes in the props.show prop, and when it changes, it updates the internal state accordingly.
 * @propTypes - It is used for type checking and documenting the expected props.
 **/

const ConfirmationPopUp = (props: { children?: ReactNode | undefined; show: any }) => {
  const [show, setShow] = useState(false);

  useEffect(() => setShow(props.show), [props.show]);
  return (
    <div
      className={`opacity-25 bg-trans justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ${
        show ? 'opacity-100 visible' : 'opacity-0 invisible'
      }`}
    >
      <div className="m-[7rem] p-[35px] mt-[19rem] bg-white rounded-md w-41% relative transition-all duration-5000">
        <div className="max-h-30% ">{props.children}</div>
      </div>
    </div>
  );
};

ConfirmationPopUp.propTypes = {
  title: PropTypes.string,
  show: PropTypes.bool.isRequired
};
export default ConfirmationPopUp;
