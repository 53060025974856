import React from 'react';
import ReactLoading from 'react-loading';

interface Props {
  isLoading?: boolean;
  error?: string;
  children: React.ReactNode;
  className?: string;
  height?: number;
  width?: number;
  color?: string;
}

const ConditionalLoader = ({
  isLoading,
  error,
  children,
  className,
  height,
  width,
  color
}: Props) => {
  if (isLoading) {
    return (
      <ReactLoading
        className={`flex justify-center ${className ?? ''}`}
        type="bubbles"
        color={color ?? '#000000'}
        height={height ?? 100}
        width={width ?? '100%'}
      />
    );
  }
  if (error) {
    return <p className="text-xl text-center text-red">{error}</p>;
  }
  return <>{children}</>;
};

export default ConditionalLoader;
