import { emailRegex } from './regex';

export const validateEmail = (email: string, domain?: Array<string>): string | null => {
  const inputEmail = email;
  if (!inputEmail) {
    return 'Please enter email.';
  } else if (!emailRegex.test(inputEmail)) {
    return 'Please enter a valid email.';
  } else if (domain?.length) {
    const emailDomain = email.split('@')[1]?.trim();
    if (!domain.includes(emailDomain)) {
      return `Invalid domain ${domain.join(' or ')}.`;
    }
  }
  return null;
};
